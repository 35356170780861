// components
import SearchFCSOnly from '@/components/SearchFCSOnly'

// config keys
import {
  // api
  FCS_REST_BASE_URI,
  FCS_UI_BASE_URI,
  // resource set filters
  FCS_REST_RESOURCE_IDS,
  FCS_REST_RESOURCE_ENDPOINTS,
  FCS_REST_RESOURCE_INSTITUTIONS,
} from '@/utils/keys'

// pinia
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { useDialogStore } from '@/stores/dialog'

// app
import { createApp as createVueApp } from 'vue'

// app plugins
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'

// locales
import de from '@/locales/de.yaml'
import en from '@/locales/en.yaml'

// --------------------------------------------------------------------------

// available locales/languages (for `switchLocale()`)
const locales = Object.keys({ de, en })

// setup pinia
const pinia = createPinia()
pinia.use(
  createPersistedState({
    storage: sessionStorage,
  })
)

// --------------------------------------------------------------------------
// functions for interaction with dialog

function openDialog({ fs = false } = {}) {
  const dialog = useDialogStore()
  dialog.open({ tab: 'content', fs: fs })
}

function switchLocale(lang = 'de') {
  const dialog = useDialogStore()
  dialog.switchLocale(lang)
}

function mountApp({
  selector,
  rest_base_uri,
  ui_base_uri = null,
  resource_ids = null,
  endpoint_urls = null,
  institution_names = null,
} = {}) {
  // create an app instance
  const app = createVueApp(SearchFCSOnly)

  // and add the plugins
  app.use(pinia)
  app.use(i18n)
  app.use(vuetify)

  // configure
  app.provide(FCS_REST_BASE_URI, rest_base_uri)
  if (ui_base_uri !== null) {
    app.provide(FCS_UI_BASE_URI, ui_base_uri)
  }
  if (resource_ids !== null) {
    app.provide(FCS_REST_RESOURCE_IDS, resource_ids)
  }
  if (endpoint_urls !== null) {
    app.provide(FCS_REST_RESOURCE_ENDPOINTS, endpoint_urls)
  }
  if (institution_names !== null) {
    app.provide(FCS_REST_RESOURCE_INSTITUTIONS, institution_names)
  }

  // mount app to DOM
  console.debug('mount app to:', selector)
  app.mount(selector)

  return app
}

// --------------------------------------------------------------------------
// expose functionality to public
const advancedsearch = { mountApp, openDialog, switchLocale, locales }
console.debug('advancedsearch', advancedsearch)
Object.assign(window, { advancedsearch })

// --------------------------------------------------------------------------
// usage example
/*
  advancedsearch.mountApp({
    selector: "#app",
    rest_base_uri: "https://fcs.text-plus.org/rest/", 
    ui_base_uri: "https://fcs.text-plus.org/",
    // optional attributes, set to null or omit to not filter!
    resource_ids: [],
    endpoint_urls: [],
    institution_names: []
  })
*/
