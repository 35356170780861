<template>
  <v-row>
    <!-- time stats -->
    <!-- medium to large screens -->
    <v-col v-if="mdAndUp" class="d-flex justify-space-evenly">
      <div style="position: relative">
        <v-field-label class="text-caption mt-n4">{{ t('label.resources') }}</v-field-label>
        <div class="h-100">
          <v-card-text class="d-flex align-center justify-left pl-0 h-100">
            <div class="d-flex flex-column align-start text-caption stats-resources-text-container">
              <span class="text-grey-darken-2">{{
                t('resources.available', [numAvailableResources])
              }}</span>
              <span class="text-black">{{
                t('resources.requested', [numRequestedResources])
              }}</span>
              <span class="text-secondary">{{
                t('resources.pending', [numPendingResources])
              }}</span>
              <span class="text-primary">{{ t('resources.matching', [numResultWithKWIK]) }}</span>
            </div>
            <v-progress-circular
              :rotate="360"
              :size="60"
              :width="4"
              :model-value="percentRequested"
              color="black"
              class="text-caption ml-2"
            >
              <v-progress-circular
                :rotate="360"
                :size="52"
                :width="4"
                :model-value="percentResult"
                color="primary"
                class="text-caption"
              >
                <v-icon :icon="mdiDatabase" />
              </v-progress-circular>
            </v-progress-circular>
          </v-card-text>
        </div>
      </div>
      <div style="position: relative">
        <v-field-label class="text-caption mt-n4">{{ t('label.timer') }}</v-field-label>
        <div class="h-100">
          <v-card-text class="d-flex align-center justify-left pl-0 h-100">
            <v-progress-circular
              :size="60"
              :width="4"
              :model-value="percentFinishedResources"
              color="secondary"
              class="text-caption"
            >
              <span class="text-black">
                <v-icon :icon="isTimerRunning ? mdiTimerPlay : mdiTimer" />{{
                  (timer / 1000).toFixed(1)
                }}
              </span>
            </v-progress-circular>
          </v-card-text>
        </div>
      </div>
      <div style="position: relative">
        <v-field-label class="text-caption mt-n4">
          {{ t('label.service') }}
          <v-chip
            :text="t('service.status.draft')"
            variant="outlined"
            size="x-small"
            label
            color="secondary"
            class="ml-2"
        /></v-field-label>
        <div class="h-100">
          <v-card-text class="d-flex flex-column align-center justify-center pl-0 h-100">
            <p class="text-center text-caption">{{ t('service.fcs') }}</p>
            <div>
              <v-btn
                icon
                size="small"
                class="mt-1 mr-1"
                :href="fcs.aggregatorUIBaseURI"
                target="_blank"
                color="secondary"
              >
                <v-tooltip
                  activator="parent"
                  location="bottom center"
                  :text="t('tooltip.openInNew', [t('service.fcs')])" />
                <v-icon :icon="mdiOpenInNew"
              /></v-btn>
              <v-btn
                icon
                size="small"
                class="mt-1"
                :disabled="!fcs.getURItoAggregatorSearch || !fcs.query"
                :href="fcs.getURItoAggregatorSearch"
                target="_blank"
                color="primary"
              >
                <v-tooltip
                  activator="parent"
                  location="bottom center"
                  :text="
                    t('tooltip.openInNew', [
                      t('tooltip.currentSearchOf', [t('service.fcs'), fcs.query]),
                    ])
                  "
                />
                <v-icon :icon="mdiSearchWeb" />
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </div>
    </v-col>

    <!-- small screens -->
    <v-col v-else class="pt-0 mb-3">
      <v-row
        ><v-col cols="5" class="py-0 text-caption text-grey-darken-2">{{
          getNumberedFacetLabel('resources', numRequestedResources, numAvailableResources)
        }}</v-col
        ><v-col cols="7" class="py-0"
          ><v-progress-linear
            :height="3"
            :model-value="percentRequested"
            color="black"
            class="mt-2"
          >
          </v-progress-linear></v-col
      ></v-row>
      <v-row
        ><v-col cols="5" class="py-0 text-caption text-secondary">{{
          t('resources.pending', [numPendingResources])
        }}</v-col
        ><v-col cols="7" class="py-0"
          ><v-progress-linear
            :height="3"
            :model-value="percentFinishedResources"
            color="secondary"
            class="mt-2"
          >
          </v-progress-linear></v-col
      ></v-row>
      <v-row
        ><v-col cols="5" class="py-0 text-caption text-primary">{{
          t('resources.matching', [numResultWithKWIK])
        }}</v-col
        ><v-col cols="7" class="py-0"
          ><v-progress-linear :height="3" :model-value="percentResult" color="primary" class="mt-2">
          </v-progress-linear></v-col
      ></v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import { useFCSStore } from 'textplus-fcs-store'
import { computed, ref, watch } from 'vue'
import { mdiTimerPlay, mdiTimer, mdiDatabase, mdiOpenInNew, mdiSearchWeb } from '@mdi/js'
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'
import { getNumberedFacetLabel } from '@/utils/Utils'

const props = defineProps({
  resourceIdsForSearch: { type: Array, default: () => [] },
  searchModified: { type: Boolean, default: false },
})
let timer = ref(0)
let intervalID = ref(null)

const { t } = useI18n()
const { mdAndUp } = useDisplay()
const fcs = useFCSStore()

const numAvailableResources = computed(() => fcs.getResources.length)
const numRequestedResources = computed(() => props.resourceIdsForSearch.length)
const numPendingResources = computed(() =>
  props.searchModified || (!fcs.isSearchFinished && fcs.getResultResourceIDsInProgress.length === 0)
    ? numRequestedResources.value
    : fcs.getResultResourceIDsInProgress.length
)
const numResultWithKWIK = computed(() =>
  props.searchModified ? 0 : fcs.getResultResourcesWithKWICResults.length
)
const percentRequested = computed(() =>
  Math.ceil((numRequestedResources.value * 100) / numAvailableResources.value)
)
const percentResult = computed(() =>
  Math.ceil((numResultWithKWIK.value * 100) / numAvailableResources.value)
)
const percentFinishedResources = computed(() =>
  Math.ceil(
    ((numRequestedResources.value - numPendingResources.value) * 100) / numRequestedResources.value
  )
)
const isTimerRunning = computed(() => intervalID.value !== null)

function startTimer() {
  timer.value = 0
  intervalID.value = setInterval(() => {
    timer.value += 100
  }, 100)
}
function clearTimer() {
  clearInterval(intervalID.value)
  intervalID.value = null
}
function resetTimer() {
  timer.value = 0
  clearTimer()
}
watch(
  () => fcs.isSearchTimeoutFinished,
  (stfN, stfO) => {
    // start timer when change from stf (isSearchTimeoutFinished) falling edge
    // that means when before there was not timeout (no active search) to now there is a timeout (new or continued search)
    if (intervalID.value === null && stfO && !stfN) {
      startTimer()
    }
    // on stf (isSearchTimeoutFinished) raising edge, clear timer as it means the search timeout finished
    if (!stfO && stfN) {
      clearTimer()
    }
  }
)
watch(timer, (timer) => {
  if (timer >= fcs.searchTimeout) {
    clearTimer()
  }
})
watch(
  () => props.searchModified,
  (mod) => {
    if (mod) {
      resetTimer()
    }
  }
)
</script>

<style scoped>
.stats-resources-text-container {
  min-width: 6rem;
}
</style>
