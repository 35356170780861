// https://stackoverflow.com/questions/57049471/problem-to-use-vuei18n-outside-a-component
import i18n from '@/plugins/i18n'
const { t } = i18n.global

/**
 * Transforms a given date string to a localized date string.
 * @param {String} date ISO datetime string
 * @param {String} locale Code used for locaization, e.g. 'de'
 * @returns {String} Localized date string according with applied format options
 */
function getLocaleDateString(date, locale) {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const d = new Date(date)
  return d.toLocaleString(locale, options)
}

function getNumberedFacetLabel(label, selected, total) {
  return selected > 0
    ? `${selected}/${total} ${t(`label.${label}`, total)}`
    : total === 0
    ? t(`label.${label}`, total)
    : `${total} ${t(`label.${label}`, total)}`
}

export { getLocaleDateString, getNumberedFacetLabel }
