import { createI18n } from 'vue-i18n'

import de from '@/locales/de.yaml'
import en from '@/locales/en.yaml'

export default createI18n({
  // vue-i18n options here
  legacy: false, // you must set `false`, to use Composition API
  locale: 'de', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: { de, en }, // set locale messages
})
