<template>
  <v-card-text class="d-flex align-center justify-center flex-wrap">
    <fcs-result-card
      v-for="(resource, idx) in sortedResources"
      :key="`fcs-resource-card-${idx}`"
      :resource="resource"
      @open-result-list-view="(rId) => emit('openResultListView', rId)"
    >
    </fcs-result-card>
  </v-card-text>
</template>

<script setup>
import { useFCSStore } from 'textplus-fcs-store'
import { computed } from 'vue'
import FcsResultCard from './FcsResultCard'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['openResultListView'])

const { locale } = useI18n()

const fcs = useFCSStore()

const sortedResources = computed(() =>
  fcs.getResources.sort((a, b) =>
    a.title.localeCompare(b.title, locale.value, { sensitivity: 'base' })
  )
)
</script>
