// Text+ Website Search
// required: API URL to list of documents for search index
export const TEXTPLUS_PORTAL_SEARCH_DOCUMENTS = Symbol()
// optional: URL to provide absolute link to documents from search (otherwise relative)
export const TEXTPLUS_PORTAL_BASE_URL = Symbol()

// FCS Search
// required (!): URL to FCS Aggregator API
export const FCS_REST_BASE_URI = Symbol()
// optional: if you want to provide search result links to FCS Aggregator, URL
export const FCS_UI_BASE_URI = Symbol()
// optional: to restrict resources by providing a list of resource ids
export const FCS_REST_RESOURCE_IDS = Symbol()
// optional: to restrict resources by providing a list of endpoint URLs
export const FCS_REST_RESOURCE_ENDPOINTS = Symbol()
// optional: to restrict resources by providing a list of institution names
export const FCS_REST_RESOURCE_INSTITUTIONS = Symbol()
