<template>
  <v-badge
    :content="getBadgeContent(resource)"
    class="ma-2"
    location="top center"
    :model-value="hasMatches"
  >
    <div class="d-flex flex-column align-center">
      <v-hover>
        <template #default="{ isHovering, props }">
          <v-card
            v-bind="props"
            class="resource-card text-center"
            :disabled="!facets.resourceIdsForSearch.includes(resource.id)"
            :text="resource.title"
            :color="
              hasMatches
                ? 'primary'
                : facets.resourceIdsForSearch.includes(resource.id)
                ? 'grey-lighten-2'
                : null
            "
            :loading="fcs.getResultResourceIDsInProgress.includes(resource.id)"
          >
            <v-expand-transition>
              <v-card
                v-if="isHovering"
                class="v-card--reveal"
                style="height: 100%"
                color="secondary"
              >
                <v-card-text class="justify-center px-1 pt-2 pb-0">
                  <!-- show results -->
                  <v-btn
                    v-if="hasMatches"
                    icon
                    size="x-small"
                    variant="text"
                    color="white"
                    @click="emit('openResultListView', resource.id)"
                    ><v-tooltip
                      activator="parent"
                      location="bottom center"
                      :text="t('tooltip.switchToResultListView')" />
                    <v-icon :icon="mdiEyeArrowRight"></v-icon
                  ></v-btn>
                  <!-- load more results -->
                  <v-btn
                    v-if="hasMoreResults"
                    icon
                    size="x-small"
                    variant="text"
                    color="white"
                    @click="fcs.loadMoreResults(resource.id)"
                    ><v-tooltip
                      activator="parent"
                      location="bottom center"
                      :text="t('tooltip.loadMoreResults')" />
                    <v-icon :icon="mdiSync"></v-icon
                  ></v-btn>
                  <!-- open landing page -->
                  <v-btn
                    icon
                    :href="resource.landingPage"
                    target="_blank"
                    size="x-small"
                    variant="text"
                    color="white"
                    ><v-tooltip
                      activator="parent"
                      location="bottom center"
                      :text="t('tooltip.openResourceLandingPage')" />
                    <v-icon :icon="mdiOpenInNew"></v-icon
                  ></v-btn>
                </v-card-text>
                <v-card-text class="text-caption px-1 pt-0 pb-2 font-weight-light">
                  {{ resource.institution }}</v-card-text
                >
              </v-card>
            </v-expand-transition>
          </v-card>
        </template>
      </v-hover>
      <div class="resource-card-meta rounded-xl text-caption text-center">
        <div
          v-for="cap in getCapabilitiesForResource(resource)"
          :key="`cap-${resource.id}-${cap}`"
          class="cap-dot rounded-xl"
          :class="getClassNameForCapabilityBackground(cap)"
        ></div>
      </div>
    </div>
  </v-badge>
</template>

<script setup>
import { mdiOpenInNew, mdiSync, mdiEyeArrowRight } from '@mdi/js'
import { useFacetsStore, useFCSStore } from 'textplus-fcs-store'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { getClassNameForCapabilityBackground, shortenCapability } from '@/utils/FcsUtils'

const props = defineProps({
  resource: {
    type: Object,
    default: () => {},
  },
})
const emit = defineEmits(['openResultListView'])

const { t } = useI18n()
const fcs = useFCSStore()
const facets = useFacetsStore()

const hasMatches = computed(() =>
  fcs.getResultResourcesWithKWICResults.map((r) => r.resource.id).includes(props.resource.id)
)
const numLoadedResults = computed(() => (hasMatches.value ? getResultsForResource().length : null))
const numTotalResults = computed(() =>
  hasMatches.value ? getResultResourceForResource().numberOfRecords : null
)
const hasMoreResults = computed(
  () => hasMatches.value && numLoadedResults.value < numTotalResults.value
)

function getResultResourceForResource() {
  return fcs.getResultResourcesWithKWICResults.find((r) => r.resource.id === props.resource.id)
}

function getResultsForResource() {
  const resultsOfResource = getResultResourceForResource()
  if (resultsOfResource) {
    return resultsOfResource.kwics
  } else {
    return []
  }
}

function getBadgeContent(resource) {
  let content = null
  if (numLoadedResults.value === numTotalResults.value || numTotalResults.value === -1) {
    content = numLoadedResults.value
  } else {
    content = t('results.xOfY', [numLoadedResults.value, numTotalResults.value])
  }
  return content
}

/**
 * Returns an array of shortened capabilities, supported by the resource. The first
 * occurence of '_' and everything after will be striped from original string.
 * @param {Object} resource A FCS resource object
 * @returns {Array} An array of capability strings, like 'BASIC'
 */
function getCapabilitiesForResource(resource) {
  return resource.searchCapabilitiesResolved.map((c) => shortenCapability(c))
}
</script>

<style scoped>
.resource-card {
  width: 8rem;
  height: 8rem;
}
.resource-card .v-card-text {
  line-height: normal;
}
.resource-card-meta {
  line-height: normal;
  margin-top: -0.5rem;
  width: 4rem;
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
  background-color: rgb(var(--v-theme-background));
  z-index: 50;
  overflow: hidden;
}
.cap-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: black;
}
.cap-dot:not(:last-of-type) {
  margin-right: 2px;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
