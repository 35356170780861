/**
 * Removes everythin after lowdash (included) in given string
 * @param {String} capString e.g. BASIC_SEARCH
 * @returns {String} e.g. BASIC
 */
function shortenCapability(capString) {
  return capString.substring(0, capString.indexOf('_'))
}

/**
 * Maps a given capability to a vuetify color background class name
 * @param {String} capString A capability, like 'BASIC'
 * @returns {String} e.g. 'bg-teal'
 */
function getClassNameForCapabilityBackground(shortenedCapString) {
  const capabilitiesColorMap = {
    basic: 'bg-grey',
    lex: 'bg-lexicals',
    advanced: 'bg-collections',
    edition: 'bg-editions',
  }
  return capabilitiesColorMap[shortenedCapString.toLowerCase()]
}

export { shortenCapability, getClassNameForCapabilityBackground }
