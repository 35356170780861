<template>
  <v-list-group
    v-if="result"
    :value="result.resource.id"
    class="tp-result-list-group"
    :class="smAndDown ? 'tp-result-list-group--dense' : null"
  >
    <template #activator="{ isOpen, props }">
      <v-lazy :min-height="48" :options="{ threshold: 0.5 }" transition="fade-transition">
        <v-list-item v-bind="props" :title="result.resource.title">
          <template #prepend>
            <v-chip
              size="small"
              color="primary"
              label
              width="3em"
              class="mr-4 tp-result-chip"
              variant="outlined"
            >
              {{ result.kwics.length }}
            </v-chip>
          </template>
          <template #append>
            <span
              v-if="mdAndUp && result.kwics.length < result.numberOfRecords"
              class="text-caption"
              >{{ $t('results.xMore', [result.numberOfRecords - result.kwics.length]) }}</span
            >
            <!--action: load more results-->
            <v-btn
              color="secondary"
              size="small"
              icon
              variant="text"
              @click.stop.prevent="fcs.loadMoreResults(result.resource.id)"
              :disabled="result.kwics.length >= result.numberOfRecords"
              :loading="
                fcs.hasSearch && fcs.getResultResourceIDsInProgress.includes(result.resource.id)
              "
            >
              <v-tooltip activator="parent" location="left" :text="t('tooltip.loadMoreResults')" />
              <v-icon :icon="mdiSync"></v-icon>
            </v-btn>
            <!--action: toggle result text expansion-->
            <v-btn
              size="small"
              icon
              variant="text"
              @click.stop.prevent="settings.expandAll = !settings.expandAll"
              :disabled="!isOpen"
            >
              <v-tooltip
                activator="parent"
                location="left"
                :text="
                  settings.expandAll ? t('tooltip.collapseAllText') : t('tooltip.expandAllText')
                "
              />
              <v-icon :icon="settings.expandAll ? mdiArrowCollapse : mdiArrowExpand"></v-icon>
            </v-btn>
            <!--action: toggle result view mode (default|kwic) -->
            <v-btn
              v-if="mdAndUp"
              size="small"
              icon
              variant="text"
              @click.stop.prevent="settings.kwicEnabled = !settings.kwicEnabled"
              :disabled="!isOpen"
              ><v-tooltip
                activator="parent"
                location="left"
                :text="
                  settings.kwicEnabled ? t('tooltip.disableKwicView') : t('tooltip.enableKwicView')
                " />
              <v-icon
                :icon="
                  settings.kwicEnabled
                    ? mdiFormatHorizontalAlignLeft
                    : mdiFormatHorizontalAlignCenter
                "
              ></v-icon
            ></v-btn>
            <v-icon :icon="isOpen ? mdiChevronUp : mdiChevronDown"></v-icon>
          </template>
        </v-list-item>
      </v-lazy>
    </template>
    <v-list-subheader
      v-if="result.resource.description || result.resource.institution"
      class="mb-2"
    >
      <p v-if="result.resource.description">
        <v-icon start :icon="mdiText" />{{ result.resource.description }}
      </p>
      <p v-if="result.resource.institution" class="font-weight-light">
        <v-icon start :icon="mdiWarehouse" />
        <a
          v-if="result.resource.institution.link"
          :href="result.resource.institution.link"
          target="_blank"
          >{{ result.resource.institution }}</a
        >
        <span v-else>{{ result.resource.institution }}</span>
      </p>
    </v-list-subheader>
    <v-lazy
      v-for="(kwic, idx) in result.kwics"
      :min-height="48"
      :options="{ threshold: 0.5 }"
      transition="fade-transition"
    >
      <v-list-item :key="`${kwic.pid}-${idx}`" density="compact">
        <v-list-item-title :class="titleClasses">
          <span class="kwic-left"
            ><span>{{ !kwic.fragments[0]?.hit ? kwic.fragments[0]?.text : null }}</span></span
          >
          <em class="kwic-hit text-primary">{{
            kwic.fragments[0]?.hit ? kwic.fragments[0]?.text : kwic.fragments[1]?.text
          }}</em>
          <span class="kwic-right"
            ><template
              v-for="(frag, fidx) in kwic.fragments.slice(kwic.fragments[0]?.hit ? 1 : 2)"
              :key="`${kwic.pid}-${idx}-${fidx}`"
            >
              <em v-if="frag.hit" :class="'kwic-hit text-primary'">{{ frag.text }}</em>
              <template v-else>{{ frag.text }}</template>
            </template></span
          >
        </v-list-item-title>
        <v-list-item-subtitle v-if="kwic.pid || kwic.reference">
          <v-btn
            v-if="kwic.reference"
            size="x-small"
            :href="kwic.reference"
            target="_blank"
            class="mr-2"
            :prepend-icon="mdiOpenInNew"
            variant="outlined"
          >
            {{ t('actions.open') }}
          </v-btn>
          <v-chip
            v-if="kwic.pid"
            variant="outlined"
            size="x-small"
            :prepend-icon="mdiIdentifier"
            label
            class="mr-2"
            >{{ kwic.pid }}</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item>
    </v-lazy>
  </v-list-group>
</template>

<script setup>
import { useFCSStore } from 'textplus-fcs-store'
import {
  mdiArrowExpand,
  mdiArrowCollapse,
  mdiText,
  mdiWarehouse,
  mdiIdentifier,
  mdiOpenInNew,
  mdiChevronDown,
  mdiChevronUp,
  mdiFormatHorizontalAlignLeft,
  mdiFormatHorizontalAlignCenter,
  mdiSync,
} from '@mdi/js'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'
import { computed, reactive } from 'vue'

const fcs = useFCSStore()

const props = defineProps(['result'])
const settings = reactive({
  expandAll: false,
  kwicEnabled: false,
})

const { t } = useI18n()
const { smAndDown, mdAndUp } = useDisplay()

const titleClasses = computed(() => {
  const cls = []
  if (mdAndUp.value && settings.kwicEnabled) {
    cls.push('kwic-enabled')
  }
  if (settings.expandAll) {
    cls.push('expanded')
  }
  return cls.join(' ')
})
</script>

<style scoped>
.tp-result-list-group--dense.v-list-group--prepend {
  --parent-padding: var(--indent-padding);
}
.v-chip.tp-result-chip {
  width: 3.3em;
  justify-content: center;
}
.v-list-group--open .v-chip.tp-result-chip {
  color: white !important;
  background-color: rgb(var(--v-theme-primary));
  border-color: rgb(var(--v-theme-primary));
}
/* KWIC center alignment */
/* only show for on mdAndUp screen sizes with mediaquery */
.v-list-item-title.kwic-enabled {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5rem;
}
.v-list-item-title.kwic-enabled > .kwic-left,
.v-list-item-title.kwic-enabled > .kwic-right {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v-list-item-title.kwic-enabled > .kwic-left {
  text-align: right;
  direction: rtl;
  unicode-bidi: bidi-override;
}
.v-list-item-title.kwic-enabled > .kwic-left > span {
  direction: ltr;
  unicode-bidi: bidi-override;
}
.v-list-item-title.kwic-enabled > .kwic-right {
  text-align: left;
}
/* row hover effects: text with chips */
.v-list-item:hover {
  /* .v-list-item__content does not seem to work? */
  background-color: rgba(var(--v-theme-on-surface), var(--v-hover-opacity));
}
/* click expand text */
.v-list-item-title.expanded > .kwic-left,
.v-list-item-title.expanded > .kwic-right {
  white-space: initial;
  text-overflow: initial;
}
/* adjust margin of chevron */
.v-list-item__append > .v-icon {
  margin-inline-start: 8px;
}
</style>
