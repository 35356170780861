/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
// https://vuetifyjs.com/en/features/icon-fonts/#material-design-icons-js-svg
export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  defaults: {
    VTooltip: {
      openDelay: 350,
    },
    VDivider: {
      /* bootstrap override (alternative would be `style: "margin-top: unset !important;"`) */
      class: 'mt-0',
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          /* colors defined in Text+ style guide */
          primary: '#cc0220',
          secondary: '#245787',
          collections: '#29a900',
          lexicals: '#e77e00',
          editions: '#00a8cc',
          infra: '#ab86f0',
        },
      },
    },
  },
})
