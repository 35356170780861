<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="dialog.isOpen"
        :fullscreen="smAndDown || dialog.fullscreen"
        class="vuetify-css"
        attach="#advanced-search"
      >
        <v-card
          :min-height="smAndDown || dialog.fullscreen ? '100%' : '95vh'"
          :min-width="smAndDown || dialog.fullscreen ? '100%' : '95vw'"
        >
          <v-toolbar dark color="primary">
            <v-toolbar-title class="flex-shrink-1">{{
              smAndDown ? t('label.textplusShort') : `${t('label.textplus')} ${t('label.search')}`
            }}</v-toolbar-title>
            <v-btn icon @click="dialog.isOpen = false">
              <v-icon :icon="mdiClose" />
            </v-btn>
          </v-toolbar>
          <!-- search sections: tab content -->
          <v-window v-model="dialog.activeTab" class="overflow-y-auto">
            <v-window-item value="content">
              <!-- Federated Content Search -->
              <fcs-search />
            </v-window-item>
          </v-window>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script setup>
import { useDialogStore } from '@/stores/dialog'
import FcsSearch from '@/components/fcs/FcsSearch'
import { useDisplay } from 'vuetify'
import { mdiClose } from '@mdi/js'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { smAndDown } = useDisplay()

const dialog = useDialogStore()
</script>
