<template>
  <v-list
    v-if="fcs.getResultResourcesWithKWICResults.length > 0"
    v-model:opened="settings.openResultDetails"
  >
    <fcs-result-list-item
      v-for="(result, idx) in sortedResources"
      :key="`fcs-result-${idx}`"
      :result="result"
      :id="`fcs-result-${idx}`"
    >
    </fcs-result-list-item>
  </v-list>
</template>

<script setup>
import { computed, reactive, onMounted } from 'vue'
import { useFCSStore } from 'textplus-fcs-store'
import FcsResultListItem from '@/components/fcs/FcsResultListItem'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  openResultDetails: { type: Array, default: () => [] },
})
const settings = reactive({
  // override (?) current open result list groups if provided by parent
  openResultDetails: props.openResultDetails.length > 0 ? props.openResultDetails : [],
})

const { locale } = useI18n()

const fcs = useFCSStore()

const sortedResources = computed(() =>
  fcs.getResultResourcesWithKWICResults.sort((a, b) =>
    a.resource.title.localeCompare(b.resource.title, locale.value, { sensitivity: 'base' })
  )
)

onMounted(() => {
  if (props.openResultDetails.length > 0) {
    const firstResourceId = props.openResultDetails[0]
    const idx = sortedResources.value.map((r) => r.resource.id).indexOf(firstResourceId)
    document.getElementById(`fcs-result-${idx}`).scrollIntoView({ behavior: 'smooth' })
  }
})
</script>
