<template>
  <v-card-text class="flex-grow-0">
    <v-row class="justify-center">
      <v-col cols="12" md="6" xl="4" :class="smAndDown ? 'pb-0' : null">
        <v-text-field
          v-model="query"
          autofocus
          clearable
          variant="outlined"
          color="primary"
          persistent-hint
          persistent-placeholder
          :label="t('label.search')"
          :placeholder="placeholder"
          :readonly="loading"
          :error="errorMessages.length > 0"
          :error-messages="errorMessages"
          :density="smAndDown ? 'compact' : 'default'"
          @keydown.enter="doSearch()"
        >
          <template #append>
            <v-icon color="primary" @click="doSearch()">
              {{ searchIcon }}
            </v-icon>
          </template>
          <template #prepend-inner>
            <v-menu>
              <template #activator="{ props }">
                <v-icon
                  :icon="mdiLayersSearch"
                  v-bind="props"
                  :color="
                    getClassNameForCapabilityBackground(
                      shortenCapability(facets.selectedSearchCapability)
                    ).replace('bg-', '')
                  "
                />
              </template>
              <v-list>
                <v-list-subheader :title="t('label.selectCapability')" />
                <v-list-item
                  v-for="cap in facets.searchCapabilityItems"
                  :key="`cap-list-item-${cap.value}`"
                  @click="facets.selectedSearchCapability = cap.value"
                  :title="t(`capability.title.${shortenCapability(cap.value).toLowerCase()}`)"
                  :subtitle="
                    t(`capability.description.${shortenCapability(cap.value).toLowerCase()}`)
                  "
                  :class="
                    getClassNameForCapabilityBackground(shortenCapability(cap.value)).replace(
                      'bg-',
                      'text-'
                    )
                  "
                  :disabled="cap.selected"
                  :active="cap.selected"
                />
              </v-list>
            </v-menu>
          </template>
        </v-text-field>
        <fcs-meta-vis
          v-if="mdAndUp"
          :resource-ids-for-search="facets.resourceIdsForSearch"
          :search-modified="modified"
        />
      </v-col>
      <!-- metadata, status, filter-->
      <v-col cols="12" md="6" xl="4">
        <v-autocomplete
          v-model="facets.selectedLanguages"
          clearable
          closable-chips
          chips
          multiple
          variant="outlined"
          hide-no-data
          :prepend-inner-icon="mdiTranslate"
          :label="
            getNumberedFacetLabel(
              'languages',
              facets.selectedLanguages.length,
              facets.languageItems.length
            )
          "
          :items="facets.languageItems"
          :disabled="loading"
          :type="mdAndUp ? 'text' : 'button'"
          :single-line="!mdAndUp"
          :density="smAndDown ? 'compact' : 'default'"
        >
          <template #item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item?.raw?.title"
              :disabled="!item.raw.active && item.raw.selected"
            >
              <template #append
                ><v-chip
                  variant="outlined"
                  label
                  size="small"
                  :color="
                    facets.getResourcesByLanguagePossible(item.raw.value).length > 0
                      ? 'primary'
                      : null
                  "
                  >{{ facets.getResourcesByLanguagePossible(item.raw.value).length }}</v-chip
                ></template
              >
            </v-list-item>
          </template>
          <template #chip="{ props, item }">
            <v-chip v-bind="props" :text="item?.raw?.title" :disabled="!item.raw.active"></v-chip>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="facets.selectedResources"
          clearable
          closable-chips
          chips
          multiple
          variant="outlined"
          hide-no-data
          :prepend-inner-icon="mdiDatabase"
          :label="
            getNumberedFacetLabel(
              'resources',
              facets.selectedResources.length,
              facets.resourceItems.length
            )
          "
          :items="facets.resourceItems"
          :disabled="loading"
          :type="mdAndUp ? 'text' : 'button'"
          :single-line="!mdAndUp"
          :density="smAndDown ? 'compact' : 'default'"
        >
          <template #item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item?.raw?.title"
              :disabled="!item.raw.active && item.raw.selected"
            ></v-list-item>
          </template>
          <template #chip="{ props, item }">
            <v-chip v-bind="props" :text="item?.raw?.title" :disabled="!item.raw.active"></v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col md="6" v-if="smAndDown">
        <fcs-meta-vis
          :resource-ids-for-search="facets.resourceIdsForSearch"
          :search-modified="modified"
        />
      </v-col>
    </v-row>
    <p class="text-caption text-center tp-result-text" :class="smAndDown ? 'pt-2' : null">
      <template v-if="fcs.query">
        {{ t('results.yourSearch') }} <strong class="text-primary">{{ fcs.query }}</strong>
        {{ t('results.returned') }}
        <strong>{{ numHitsTotal }} {{ t('results.result', numHitsTotal) }}</strong> ({{
          numHitsLoaded
        }}
        {{ t('results.loaded') }}) in
        <strong>{{ numResultWithKWIK }} {{ t('results.resources', numResultWithKWIK) }}</strong>
      </template>
      <template v-else>&nbsp;</template>
    </p>
  </v-card-text>
  <!-- result view modes -->
  <div class="d-flex justify-space-around mb-n5 result-view-btns">
    <v-btn-toggle
      v-model="settings.resultMode"
      rounded="xl"
      variant="outlined"
      divided
      density="compact"
      mandatory
    >
      <v-btn :prepend-icon="mdiGrid">{{ t('label.cards') }}</v-btn>
      <v-btn :prepend-icon="mdiFormatListBulletedSquare"
        ><v-badge
          floating
          dot
          color="primary"
          :model-value="settings.resultMode === 0 && numResultWithKWIK > 0"
          >{{ t('label.list') }}</v-badge
        ></v-btn
      >
    </v-btn-toggle>
  </div>
  <v-divider class="mb-5"></v-divider>
  <!-- results -->
  <fcs-result-card-view
    @open-result-list-view="switchToResultListViewForResource"
    v-if="settings.resultMode === 0"
  />
  <fcs-result-list :open-result-details="settings.openResultDetails" v-else></fcs-result-list>
</template>

<script setup>
import { useFCSStore, useFacetsStore } from 'textplus-fcs-store'
import { useDialogStore } from '@/stores/dialog'
import FcsMetaVis from '@/components/fcs/FcsMetaVis'
import FcsResultList from '@/components/fcs/FcsResultList'
import FcsResultCardView from '@/components/fcs/FcsResultCardView'
import { shortenCapability, getClassNameForCapabilityBackground } from '@/utils/FcsUtils'
import { getNumberedFacetLabel } from '@/utils/Utils'
import {
  FCS_REST_BASE_URI,
  FCS_UI_BASE_URI,
  FCS_REST_RESOURCE_IDS,
  FCS_REST_RESOURCE_ENDPOINTS,
  FCS_REST_RESOURCE_INSTITUTIONS,
} from '@/utils/keys'
import { reactive, ref, onMounted, onUnmounted, computed, watch, toRaw, inject } from 'vue'
import {
  mdiSend,
  mdiSendClock,
  mdiSendCheck,
  mdiGrid,
  mdiFormatListBulletedSquare,
  mdiLayersSearch,
  mdiTranslate,
  mdiDatabase,
} from '@mdi/js'
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'

const { t, locale } = useI18n()
const { smAndDown, mdAndUp } = useDisplay()

const fcs = useFCSStore()
const facets = useFacetsStore()
const dialog = useDialogStore()

const query = ref(null)
const placeholder = ref(null)
const placeholderIdx = ref(0)
const settings = reactive({
  openResultDetails: [],
  resultMode: 0,
})
const modified = ref(false)

const loading = computed(() => fcs.getResultResourceIDsInProgress.length > 0 && fcs.hasSearch)

const placeholderQuery = computed(() =>
  t(
    `hint.examples.${shortenCapability(toRaw(facets.selectedSearchCapability)).toLowerCase()}[${
      placeholderIdx.value
    }]`
  )
)

const errorMessages = computed(() => fcs.error.map((errorArray) => errorArray.message))
const numResultWithKWIK = computed(() => fcs.getResultResourcesWithKWICResults.length)
const numHitsLoaded = computed(() =>
  fcs.getResultResourcesWithKWICResults.map((r) => r.kwics.length).reduce((a, b) => a + b, 0)
)
const numHitsTotal = computed(() =>
  fcs.getResultResourcesWithKWICResults.map((r) => r.numberOfRecords).reduce((a, b) => a + b, 0)
)

const searchIcon = computed(() => {
  return modified.value ? mdiSend : fcs.isSearchFinished ? mdiSendCheck : mdiSendClock
})

function doSearch() {
  // don't trigger a search request if nothing has changed
  if (!modified.value) return
  // use example query if no query is manually provided
  if (!query.value) {
    query.value = placeholderQuery.value
  }

  fcs.search(
    query.value,
    facets.resourceIdsForSearch,
    fcs.searchCapabilityToQueryType(facets.selectedSearchCapability)
  )
  // reset possible modification
  modified.value = false
}

var queryPlaceholderTimer = null

function switchToResultListViewForResource(resourceId) {
  // empty array and then only add the single item
  settings.openResultDetails = [resourceId]
  // switch from card to list
  settings.resultMode = 1
}

onMounted(() => {
  fcs.init({
    apiRestBaseURI: inject(FCS_REST_BASE_URI),
    aggregatorUIBaseURI: inject(FCS_UI_BASE_URI),
    searchTimeout: Infinity,
  })
  // trigger with timeout since init is async and not blocking
  // also because watchers do not seem to trigger on fast startup
  if (
    !!resourceIdsForFiltering ||
    !!resourceEndpointsForFiltering ||
    !!resourceInstitutionsForFiltering
  ) {
    setTimeout(() => {
      restrictResources()
    }, 500)
  }

  // reuse previous query from store
  query.value = fcs.query
  // search example hints
  startQueryPlaceholderAnimation()
})
onUnmounted(() => {
  stopQueryPlaceholderAnimation()
})

// query placeholder animation
function startQueryPlaceholderAnimation() {
  // check if already running
  if (queryPlaceholderTimer !== null) {
    stopQueryPlaceholderAnimation()
  }
  // check if first time
  if (placeholder.value === null) {
    switchQueryPlaceholder()
  }
  queryPlaceholderTimer = setInterval(() => {
    switchQueryPlaceholder()
  }, 5000)
}
function stopQueryPlaceholderAnimation() {
  clearInterval(queryPlaceholderTimer)
  queryPlaceholderTimer = null
}
function switchQueryPlaceholder() {
  // get random example for selected search capability
  while (true) {
    const rIdx = Math.floor(Math.random() * 5) // 0, 1 or 2}
    if (rIdx !== placeholderIdx.value) {
      placeholderIdx.value = rIdx
      break
    }
  }

  let exampleQuery = placeholderQuery.value
  if (exampleQuery.indexOf('hint.examples') === 0) {
    // if we have an invalid index, just reset to zero and use first one
    placeholderIdx.value = 0
    exampleQuery = placeholderQuery.value
  }
  placeholder.value = t('hint.examples.prefix', [exampleQuery])
}

var resourceIdsForFiltering = inject(FCS_REST_RESOURCE_IDS, null)
var resourceEndpointsForFiltering = inject(FCS_REST_RESOURCE_ENDPOINTS, null)
var resourceInstitutionsForFiltering = inject(FCS_REST_RESOURCE_INSTITUTIONS, null)
function restrictResources() {
  if (
    !!resourceIdsForFiltering ||
    !!resourceEndpointsForFiltering ||
    !!resourceInstitutionsForFiltering
  ) {
    let resourceIds = []

    console.debug('Restrict resources (with ids) to:', resourceIdsForFiltering)
    if (!!resourceIdsForFiltering) {
      console.debug(`(Filter) Resources by IDs:`, resourceIdsForFiltering)
      resourceIds = resourceIds.concat(resourceIdsForFiltering)
    }

    console.debug('Restrict resources (with endpoints) to:', resourceEndpointsForFiltering)
    if (!!resourceEndpointsForFiltering) {
      // gather resource ids based on endpoint url
      resourceEndpointsForFiltering.forEach((endpoint) => {
        let endpointResourceIds = fcs.getResourcesByEndpoint(endpoint).map((r) => r.id)
        console.debug(`(Filter) Resources from ${endpoint}:`, endpointResourceIds)
        resourceIds = resourceIds.concat(endpointResourceIds)
      })
    }

    console.debug('Restrict resources (with institutions) to:', resourceInstitutionsForFiltering)
    if (!!resourceInstitutionsForFiltering) {
      // gather resource ids based on institution name
      resourceInstitutionsForFiltering.forEach((institute) => {
        let institutionResourceIds = fcs.getResourcesByInstitution(institute).map((r) => r.id)
        console.debug(`(Filter) Resources from ${institute}:`, institutionResourceIds)
        resourceIds = resourceIds.concat(institutionResourceIds)
      })
    }

    console.debug('Final set of resource IDs:', resourceIds)
    if (resourceIds.length > 0) {
      // console.debug('before:', fcs.getResourceIDs)
      fcs.restrictToResources(resourceIds)
      // console.debug('after:', fcs.getResourceIDs)
    }
  }
}

// set locale (initial value)
facets.localeForCompare = locale.value

// query placeholder animation control
watch(
  () => facets.selectedSearchCapability,
  (capN, capO) => {
    if (capN !== capO) {
      switchQueryPlaceholder()
    }
  }
)
watch(
  () => dialog.activeTab,
  (tabN, tabO) => {
    if (tabN !== tabO) {
      if (tabN === 'content') {
        startQueryPlaceholderAnimation()
      } else {
        stopQueryPlaceholderAnimation()
      }
    }
  }
)
// modified
watch([query, () => facets.resourceIdsForSearch], ([qN, idsN], [qO, idsO]) => {
  // if search parameter, like resources or query, change, while there is a running
  // search, we have to start a new search.
  // note: allow changes if no search exists or if search is finished
  if ((!fcs.hasSearch || fcs.isSearchFinished) && !modified.value && (qN !== qO || idsN !== idsO)) {
    // if query field was empty or reset (null)
    // ... and we accepted the placeholder suggestion
    // ... then skip modified change as we started a search, probably
    if ((qO === '' || qO === null) && qN === placeholderQuery.value) {
      return
    }
    modified.value = true
  }
})
// locale to facets store
watch(locale, (lN, lO) => {
  if (lN !== lO) {
    facets.localeForCompare = lN
  }
})
// restrict to resources
if (
  !!resourceIdsForFiltering ||
  !!resourceEndpointsForFiltering ||
  !!resourceInstitutionsForFiltering
) {
  watch(
    () => fcs.getResourceIDs,
    (rN, rO) => {
      // NOTE: not sure about this condition
      if (rN.toString() !== rO.toString()) {
        console.debug('Check for resource restriction on FCS Store changed resources ...')
        restrictResources()
      }
    }
  )
}
</script>

<style scoped>
.result-view-btns {
  position: relative; /* position:static (could be default on element) prevents z-index from having any effect */
  z-index: 100;
}
.result-view-btns .v-btn {
  background-color: white;
}
</style>
