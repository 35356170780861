import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const useDialogStore = defineStore('dialog', () => {
  const { locale } = useI18n()

  // ------------------------------------------------------------------------
  // State

  const isOpen = ref(false)
  const activeTab = ref(null)
  const fullscreen = ref(true)

  // ------------------------------------------------------------------------
  // Actions

  function open({ tab = 'website', fs = false } = {}) {
    isOpen.value = true
    fullscreen.value = fs
    // TODO: better way to switch between options and if invalid go to default?
    activeTab.value = tab === 'content' ? tab : tab === 'metadata' ? tab : 'website'
  }

  function switchLocale(lang = 'de') {
    locale.value = lang
  }

  return { isOpen, activeTab, fullscreen, locale, open, switchLocale }
})
